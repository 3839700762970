import axios from "axios";
let apiHost = "https://api.mygivhub.org";
//local override
// apiHost = "http://localhost:8080";

export const isBrowser = () => typeof window !== "undefined";

const setUser = async user => {
  return await window.localStorage.setItem("givHubUser", JSON.stringify(user));
};

export const getUser = () => {
  return isBrowser() && window.localStorage.getItem("givHubUser")
    ? JSON.parse(window.localStorage.getItem("givHubUser"))
    : {};
};
export const fetchUserInfo = token => {
  return axios
    .get(apiHost + "/user/me", {
      headers: {
        "x-access-token": token
      }
    })
    .then(res => {
      let data = JSON.parse(res.data.Payload);
      if (data.error) {
        return Promise.reject(data.error);
      } else {
        return data;
      }
    })
    .catch(console.error);
};
export const getUserOrgs = async token => {
  return await axios
    .get(apiHost + "/user/orgs", {
      headers: {
        "x-access-token": token
      }
    })
    .then(res => {
      let data = JSON.parse(res.data.Payload);
      if (data.error) {
        return Promise.reject(data.error);
      } else {
        return data.data.donationpages;
      }
    })
    .catch(console.error);
};

export const registerUser = ({
  fname,
  lname,
  name,
  email,
  password,
  promocode
}) => {
  return axios
    .post(`${apiHost}/user/register`, {
      fname,
      lname,
      name,
      email,
      password,
      promocode
    })
    .then(res => {
      let data = JSON.parse(res.data.Payload);
      if (data.error) {
        return Promise.reject(data.error);
      }
      return setUser(data);
    });
};

export const handleLogin = ({ email, password }) => {
  return axios
    .post(
      `${apiHost}/user/login`,
      {
        email,
        password
      },
      {
        crossDomain: true
      }
    )
    .then(res => {
      let data = JSON.parse(res.data.Payload);
      if (data.error) {
        return Promise.reject(data.error);
      }
      return setUser(data);
    });
};

export function isLoggedIn() {
  const user = getUser();
  return !!user.jwt;
}

export const logout = async callback => {
  try {
    window.localStorage.clear();
    const keys = await window.caches.keys();
    await Promise.all(keys.map(key => caches.delete(key)));
    callback();
  } catch (error) {
    console.error(error);
    callback();
  }
};
