import React from "react";
import HomePage from "../components/layout/Home";
import SignUp from "../components/home/signup";

export default props => {
  return (
    <HomePage>
      <SignUp />
    </HomePage>
  );
};
