import React, { Component } from "react";
import { navigate, Link } from "gatsby";
import "../../../static/styles/_login-modal.scss";
import TakeOver from "../common/Takeover";
import styled from "styled-components";
import { isLoggedIn, registerUser } from "../../services/auth";
import Bars from "../../../static/img/assets/bars.svg";
import CancelIcon from "@material-ui/icons/Cancel";
let Close = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  filter: invert(1);
  svg {
    fill: #fff;
  }
`;

const Progress = ({ screen }) => {
  return (
    <ul className="progress">
      <li className={screen > 0 ? "done" : ""} />
      <li className={screen > 1 ? "done" : ""} />
      <li className={screen > 2 ? "done" : ""} />
      <li className={screen > 3 ? "done" : ""} />
    </ul>
  );
};
export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.input = [];
    this.state = {
      loading: true,
      active: 1,
      fname: "",
      lname: "",
      email: "",
      password: "",
      password_confirm: "",
      promocode: ""
    };
  }
  registerUser = e => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    const { password_confirm, password } = this.state;
    if (password === password_confirm) {
      let user = {
        ...this.state,
        name: `${this.state.fname} ${this.state.lname}`
      };
      registerUser(user)
        .then(res => {
          if (isLoggedIn()) {
            navigate("/dashboard/hub/home");
          } else {
            alert(
              "Hmm something went wrong, we're probably fixing it right now!"
            );
          }
        })
        .catch(error => {
          this.setState({
            loading: false
          });
        });
    } else {
      document.querySelector("#login button").classList.add("no");
      setTimeout(() => {
        document.querySelector("#login button").classList.remove("no");
        this.setState({
          password_confirm: ""
        });
      }, 1000);
      this.setState({
        loading: false
      });
    }
  };
  updateForm = (key, e) => {
    let newState = {};
    newState[key] = e.target.value;
    this.setState(newState);
  };
  updateScreen = (screen, e) => {
    e.preventDefault();
    this.setState(
      {
        active: screen
      },
      () => {}
    );
  };
  componentDidMount() {
    if (isLoggedIn()) {
      navigate("/dashboard/hub/home");
    }
    if (window.location.hash) {
      window.localStorage.setItem(
        "_toAddAfter",
        window.location.hash.split("/#")[1]
      );
    }
    this.setState({
      loading: false
    });
    if (this.input[this.state.active]) {
      this.input[this.state.active].focus();
    }
  }
  render() {
    const { active } = this.state;
    return (
      <>
        <TakeOver
          onClick={e => {
            e.preventDefault();
            navigate("/");
          }}
        >
          <div
            className="modal"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <div className="left-half darkbluebg">
              <h2>Hubraisers welcome!</h2>
            </div>
            <div className={"right-half darkbluebg step-" + active}>
              {this.state.loading && (
                <div
                  style={{
                    color: "#000000",
                    width: "100%",
                    height: "100%",
                    display: "grid",
                    placeContent: "center center",
                    background: "rgba(0, 0, 0, 0.5)",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "100"
                  }}
                >
                  <img alt="please wait" src={Bars} />
                </div>
              )}

              <Close className="closeX" to={"/"}>
                <CancelIcon />
              </Close>

              <Progress screen={active} />
              <div>
                <span className={active === 1 ? "screen active" : "screen"}>
                  <p>
                    Hello{" "}
                    <span role="img" aria-label="hello">
                      👋
                    </span>
                  </p>
                  <p>What’s your full name?</p>
                  <form onSubmit={this.updateScreen.bind(this, 2)}>
                    <input
                      ref={c => (this.input[1] = c)}
                      autoFocus={true}
                      onChange={this.updateForm.bind(this, "fname")}
                      type="text"
                      value={this.state.fname}
                      placeholder="First "
                      required
                      autoComplete="fname"
                    />
                    <input
                      onChange={this.updateForm.bind(this, "lname")}
                      type="text"
                      value={this.state.lname}
                      placeholder="Last"
                      required
                      autoComplete="lname"
                    />
                    <div className="button-wrapper">
                      <button className="center" type="submit">
                        Next
                      </button>
                    </div>
                  </form>
                </span>
                <span className={active === 2 ? "screen active" : "screen"}>
                  <p>
                    Awesome{" "}
                    <span role="img" aria-label="great">
                      👍
                    </span>
                  </p>
                  <p>What’s your email address?</p>
                  <form onSubmit={this.updateScreen.bind(this, 3)}>
                    <input
                      ref={c => (this.input[2] = c)}
                      autoFocus={true}
                      onChange={this.updateForm.bind(this, "email")}
                      type="email"
                      value={this.state.email}
                      placeholder="Email Address"
                      required
                      autoComplete="email"
                    />
                    <div className="button-wrapper">
                      <div
                        className="backarrow"
                        onClick={this.updateScreen.bind(this, active - 1)}
                      >
                        ⬅
                      </div>
                      <button type="submit">Next</button>
                    </div>
                  </form>
                </span>
                <span className={active === 3 ? "screen active" : "screen"}>
                  <p>
                    Great{" "}
                    <span role="img" aria-label="ok">
                      👌
                    </span>
                  </p>
                  <p>Now pick a password?</p>
                  <form onSubmit={this.updateScreen.bind(this, 4)}>
                    <input
                      ref={c => (this.input[3] = c)}
                      autoFocus={true}
                      value={this.state.password}
                      onChange={this.updateForm.bind(this, "password")}
                      type="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      minLength="6"
                      required
                    />
                    <div className="button-wrapper">
                      <div
                        className="backarrow"
                        onClick={this.updateScreen.bind(this, active - 1)}
                      >
                        ⬅
                      </div>
                      <button type="submit">Next</button>
                    </div>
                  </form>
                </span>
                <span className={active === 4 ? "screen active" : "screen"}>
                  <p>
                    Almost Done{" "}
                    <span role="img" aria-label="wait">
                      ✋
                    </span>
                  </p>
                  <p>Let's confirm that password</p>
                  <form onSubmit={this.registerUser}>
                    <input
                      ref={c => (this.input[4] = c)}
                      autoFocus={true}
                      onChange={this.updateForm.bind(this, "password_confirm")}
                      type="password"
                      autoComplete="current-password"
                      value={this.state.password_confirm}
                      placeholder="Type Password Again"
                      minLength="6"
                      required
                    />
                    <input
                      style={{
                        marginTop: "10rem",
                        maxWidth: "100px",
                        display: "none"
                      }}
                      ref={c => (this.input[5] = c)}
                      onChange={this.updateForm.bind(this, "promocode")}
                      type="text"
                      value={this.state.promocode}
                      placeholder="Promo Code"
                      minLength="2"
                    />
                    <div id="login" className="button-wrapper">
                      <div
                        className="backarrow"
                        onClick={this.updateScreen.bind(this, active - 1)}
                      >
                        ⬅
                      </div>
                      <button type="submit">Login</button>
                    </div>
                  </form>
                </span>
              </div>
            </div>
          </div>
        </TakeOver>
      </>
    );
  }
}
